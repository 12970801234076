import Vue from 'vue'
// import rolesPermissions from '../utils/rolesPermissions.js'
Vue.mixin({
  methods: {
    can (slug) {
      return true
      // return rolesPermissions.isHasPermission(slug)
    }
  }
})
