import { PATIENT_SOURCE_TYPE } from './constants'

export const mixinGetPersonInfo = {
  methods: {
    getAddress (data, fallback = '') {
      if (data?.source_type === PATIENT_SOURCE_TYPE.HIS) {
        return data.address
      }

      let result = ''
      if (data?.address) {
        result += data.address
      }

      if (data?.address_detail) {
        result += data.address_detail
      }

      if (data?.address_wards_name) {
        result = result + ', ' + data.address_wards_name
      }
      if (data?.address_district_name) {
        result = result + ', ' + data.address_district_name
      }
      if (data?.address_province_name) {
        result = result + ', ' + data.address_province_name
      }
      return result || fallback
    }
  }
}
