import Vue from 'vue'
const prototype = Vue.prototype
const bucketName = 'hodo-resource'
let aws = window.AWS
const prefix = 'resources/circles/'
const region = 'ap-southeast-1'
const IdentityPoolId = 'ap-southeast-1:ff53cfbf-2595-4ade-83b8-43e3ad25f279'

aws.config.region = region // 1. Enter your region
aws.config.credentials = new aws.CognitoIdentityCredentials({
  IdentityPoolId: IdentityPoolId // 2. Enter your identity pool
})
aws.config.credentials.get(function (err) {
  if (err) alert(err)
  // console.log(AWS.config.credentials);
})

const uploadCircleFile = {
  async circleUploadHanlder (circleId, folderId, files, type, isVideo = false) {
    if (!files || !files.length) return

    const resultURL = []

    // await files.forEach(async (file, i) => {
    //   if (file) {
    //     console.log(file)
    //     let options = {
    //       circle_id: circleId,
    //       file: file,
    //       type: type
    //     }
    //     if (folderId) {
    //       options.folder_id = folderId
    //     }
    //     const _prefix = type === 3 ? 'pacdicom' : (prefix)
    //     let newPrefix =
    //       _prefix +
    //       circleId +
    //       '/' +
    //       (window.moment().unix() + i) +
    //       '_' +
    //       file.name
    //     const fileURL = await this.s3ResourcesUploader(newPrefix, options, isVideo)
    //     resultURL.push(fileURL)
    //   }
    // })

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (file) {
        let options = {
          circle_id: circleId,
          file: file,
          type: type
        }
        if (folderId) {
          options.folder_id = folderId
        }
        const _prefix = type === 3 ? 'pacdicom' : (prefix)
        let newPrefix =
          _prefix +
          circleId +
          '/' +
          (window.moment().unix() + i) +
          '_' +
          file.name
        const fileURL = await this.s3ResourcesUploader(newPrefix, options, isVideo)
        resultURL.push(fileURL)
      } else {
        resultURL.push(null)
      }
    }

    return resultURL
  },
  async s3ResourcesUploader (path, options, isVideo) {
    let self = this
    let bucket = null
    const _bucketName = options.type === 3 ? 'hodo-pacdicom' : (bucketName)

    bucket = new aws.S3({
      params: {
        Bucket: _bucketName
      }
    })

    if (options.file) {
      let pac = null

      const file = options.file
      const objKey = path
      const params = {
        Key: objKey,
        ContentType: file.type,
        Body: file
        // ACL: 'public-read'
      }
      //   this.progress.show = true
      //   this.progress.title = 'Đang tải lên Ảnh DICOM'
      //   this.progress.name = file.name
      //   this.$uploading = true
      let uploadingInfo = {
        id: this.makeid() + window.moment().unix(),
        name: file.name,
        value: 0,
        status: 0
      }
      prototype.$multiUploading.push(uploadingInfo)

      const UploadBucket = await bucket
        .upload(params)

      await bucket
        .upload(params).on('httpUploadProgress', function (evt) {
          var uploaded = Math.round((evt.loaded / evt.total) * 100)
          uploadingInfo.value = uploaded
          var foundIndex = prototype.$multiUploading.findIndex(
            (x) => x.id === uploadingInfo.id
          )
          prototype.$multiUploading[foundIndex] = uploadingInfo
        }).send(async function (err, data) {
          var foundIndex = prototype.$multiUploading.findIndex(
            (x) => x.id === uploadingInfo.id
          )
          if (err) {
            uploadingInfo.status = 2
            // an error occurred, handle the error
            console.log('upload failed', err, err.stack)
            prototype.$multiUploading[foundIndex] = uploadingInfo
            setTimeout(() => {
              if (foundIndex > -1) {
                prototype.$multiUploading.splice(foundIndex, 1)
              }
            }, 800)
            return
          }

          if (options.type === 3) {
            pac = await self.onUploadPac(
              options.file.name,
              options.type,
              data.Key
            )
          }

          let params = {
            circle_id: options.circle_id,
            type: options.type,
            name: options.file.name,
            pac_id: pac ? pac.id : null,
            file_name: data.Key
          }

          if (options.folder_id) {
            params.folder_id = options.folder_id
          }

          uploadingInfo.status = 1
          prototype.$multiUploading[foundIndex] = uploadingInfo
          setTimeout(() => {
            if (foundIndex > -1) {
              prototype.$multiUploading.splice(foundIndex, 1)
            }
          }, 800)
          setTimeout(() => {
            if (!prototype.$multiUploading?.length) prototype.$refresh++
          }, 900)
          if (!isVideo) {
            self.createCircleAttach(params)
          } else {
            // return self.getLinkVideoAWS(params)
          }
        })

      const result = UploadBucket.promise().then(data => {
        return data.Key
      })
      return result
    } else {
      return false
    }
  },
  async onUploadPac (name, type, key) {
    const params = {
      name: name,
      file_name: key
    }
    let r = await prototype.$rf.getRequest('DoctorRequest').createPacs(params)

    if (type === 3 && r?.data) {
      await this.pacUpdate(key, r?.data)
    }
    return r?.data
  },
  async pacUpdate (url, access) {
    const params = {
      files: url
    }
    let r = await prototype.$rf
      .getRequest('DoctorRequest')
      .updatePacs(access.id, params)

    return r
  },
  async createCircleAttach (options) {
    let r = await prototype.$rf
      .getRequest('DoctorRequest')
      .createCircleAttachment(options)
      .then((r) => {
        return r.data
      })
    return r
  },
  makeid (length) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },
  async getLinkVideoAWS (file_name) {
    try {
      const params = {
        fileName: file_name
      }
      const header = {
        'x-api-key': process.env.VUE_APP_AWS_API_KEY
      }

      const response = await prototype.$rf
        .getRequest('AuthRequest').getLinkHodoResourceAws(params, header, false)

      return response.data.data
    } catch (error) {
      console.log(error)
    }
  }
}

export default uploadCircleFile
