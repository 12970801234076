import { Line, mixins } from 'vue-chartjs'

import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['settings', 'options', 'isDownload'],
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    isDownload (value) {
      this.downloadChart()
    }
  },
  methods: {
    async downloadChart () {
      const self = this
      const dateTime = Date.now()
      const { labels, padding } = self.chartData
      const chartsRaw = [
        {
          data: self.chartData,
          scales: {
            ...self.options.scales,
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false,
                  drawOnChartArea: true,
                  color: 'black',
                  tickMarkLength: 0
                },
                ticks: {
                  padding: 0,
                  display: false
                }
              }
            ],
            yAxes: [
              {
                id: 'A',
                type: 'linear',
                position: 'left',
                offset: false,
                ticks: {
                  display: false,
                  stepSize: 2,
                  min: 40,
                  max: 160,
                  padding: 0
                },
                gridLines: {
                  display: false,
                  drawBorder: false,
                  drawOnChartArea: false,
                  color: 'black',
                  tickMarkLength: 0
                }
              },
              {
                id: 'B',
                type: 'linear',
                position: 'right',
                offset: false,
                ticks: {
                  display: false,
                  stepSize: 0.1,
                  min: 35,
                  max: 41,
                  padding: 0
                },
                gridLines: {
                  display: false,
                  drawBorder: false,
                  drawOnChartArea: false,
                  color: 'black',
                  tickMarkLength: 0
                }
              }
            ]
          }
        },
        {
          data: {
            labels,
            padding
          },
          scales: {
            ...self.options.scales,
            xAxes: [
              {
                gridLines: {
                  display: true,
                  drawBorder: false,
                  drawOnChartArea: true,
                  color: 'black',
                  tickMarkLength: 0
                },
                ticks: {
                  padding: 0,
                  display: false
                }
              }
            ],
            yAxes: [
              {
                id: 'A',
                type: 'linear',
                position: 'left',
                offset: false,
                ticks: {
                  display: false,
                  stepSize: 2,
                  min: 40,
                  max: 160,
                  padding: 0
                },
                gridLines: {
                  display: false,
                  drawBorder: false,
                  drawOnChartArea: false,
                  color: 'black',
                  tickMarkLength: 0
                }
              },
              {
                id: 'B',
                type: 'linear',
                position: 'right',
                offset: false,
                ticks: {
                  display: false,
                  stepSize: 0.1,
                  min: 35,
                  max: 41,
                  padding: 0
                },
                gridLines: {
                  display: true,
                  drawBorder: false,
                  drawOnChartArea: true,
                  color: 'black',
                  tickMarkLength: 0
                }
              }
            ]
          }
        }
      ]
      const imageData = await Promise.all(
        chartsRaw.map(async (item, index) => {
          const result = await self.renderImageChart(
            item.data,
            item.scales,
            dateTime
          )
          return {
            ...result,
            type: index + 1
          }
        })
      )

      self.$emit('setImageChart', imageData)
    },
    async renderImageChart (chartData, scales, dateTime) {
      const self = this
      const defaultData = {
        type: 'line',
        data: chartData,
        plugins: [ChartDataLabels],
        options: {
          ...self.options,
          plugins: {
            datalabels: {
              backgroundColor: 'white',
              color: function (context) {
                return context.dataset.borderColor
              },
              borderRadius: 4,
              font: {
                weight: 'bold',
                size: 10
              },
              padding: 2
            }
          },
          devicePixelRatio: 1,
          scales
        }
      }
      const newChart = new Chart(self.$refs.canvas, {
        ...defaultData
      })

      newChart.update({
        duration: 0
      })

      let dataRaw = await newChart.toBase64Image()
      const data = dataRaw.replace(/^data:image\/[a-z]+;base64,/, '')

      newChart.options = self.options
      newChart.update({
        duration: 0
      })

      const imageData = {
        src: `data:image/png;base64,${data}`,
        alt: `chartImage_${dateTime || Date.now()}.png`
      }

      return imageData
    }
  }
}
