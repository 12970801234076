import { APP_MODE } from '@/utils/constants'

export const mixinHasFeatures = {
  computed: {
    hasMammo () {
      return (
        (this.$featureList && this.$featureList['mammo']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['mammo']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['mammo']
            : false) ||
        false
      )
    },
    hasAnapath () {
      return (
        (this.$featureList && this.$featureList['anapath']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['anapath']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['anapath']
            : false) ||
        false
      )
    },
    hasReception () {
      return (
        (this.$featureList && this.$featureList['reception']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['reception']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['reception']
            : false) ||
        false
      )
    },
    hasExamination () {
      return (
        (this.$featureList && this.$featureList['examination']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['examination']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['examination']
            : false) ||
        false
      )
    },
    hasSchedule () {
      return (
        (this.$featureList && this.$featureList['schedule']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['schedule']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['schedule']
            : false) ||
        false
      )
    },
    hasEcom () {
      if (this.$appMode === APP_MODE.organization) {
        return this.$featureOrgList && this.$featureOrgList['ecom']
      }
      if (this.$appMode === APP_MODE.workspace) {
        return this.$featureWsList && this.$featureWsList['ecom']
      }
      return (this.$featureList && this.$featureList['ecom']) || false
    },
    hasConference () {
      if (this.$appMode === APP_MODE.organization) {
        return this.$featureOrgList && this.$featureOrgList['conference']
      }

      return (
        (this.$featureList && this.$featureList['conference']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['conference']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['conference']
            : false) ||
        false
      )
    },
    hasBadt () {
      return (
        (this.$featureList && this.$featureList['badt']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['badt']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['badt']
            : false) ||
        false
      )
    },
    hasOrgMdt () {
      return (
        (this.$featureList && this.$featureList['orgmdt']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['orgmdt']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['orgmdt']
            : false) ||
        false
      )
    },
    hasPharma () {
      return (
        (this.$featureList && this.$featureList['pharma']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['pharma']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['pharma']
            : false) ||
        false
      )
    },
    hasHSSK () {
      return (
        (this.$featureList && this.$featureList['hssk']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['hssk']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['hssk']
            : false) ||
        false
      )
    },
    hasHealthRecordsOverview () {
      return (
        (this.$featureList && this.$featureList['hsskOverview']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['hsskOverview']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['hsskOverview']
            : false) ||
        false
      )
    },
    hasSkb () {
      return (
        (this.$featureList && this.$featureList['skb']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['skb']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['skb']
            : false) ||
        false
      )
    },
    hasBedMgmt () {
      return (
        (this.$featureList && this.$featureList['bedmgnt']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['bedmgnt']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['bedmgnt']
            : false) ||
        false
      )
    },
    hasEcomMarketplace () {
      return (
        (this.$featureList && this.$featureList['ecomMk']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['ecomMk']
          : false) ||
        false
      )
    },
    hasHODOCredit () {
      return (
        (this.$featureList && this.$featureList['credit']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['credit']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['credit']
            : false) ||
        false
      )
    },
    hasManageEcomMK () {
      return (
        (this.$featureList && this.$featureList['ecomWarehouse']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['ecomWarehouse']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['ecomWarehouse']
            : false) ||
        false
      )
    },
    hasPayment () {
      return (
        (this.$featureList && this.$featureList['payment']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['payment']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['payment']
            : false) ||
        false
      )
    },
    hasExaminationEcom () {
      return (
        (this.$featureList && this.$featureList['examEcom']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['examEcom']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['examEcom']
            : false) ||
        false
      )
    },
    hasVimesSync () {
      return (
        (this.$featureList && this.$featureList['vimesSync']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['vimesSync']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['vimesSync']
            : false) ||
        false
      )
    }
  }
}
