import ModalAddPerson from '../components/Person/ModalAddPerson'
import appUtils from './appUtils'
export const mixinSelectPerson = {
  components: { ModalAddPerson },
  computed: {
    className () {
      return appUtils.isClinic() ? 'ClinicRequest' : appUtils.isDoctor() ? 'DoctorRequest' : ''
    }
  },
  data () {
    return {
      selected_person: null,
      add_birth: {
        day: '',
        month: '',
        year: ''
      },
      add_person: {
        name: '',
        phone: '',
        gender: '',
        cmnd: '',
        address: '',
        symptom: ''
      }
    }
  },
  methods: {
    setDefaultPerson (id) {
      let self = this
      let person = null
      if (self.isClinic) {
        person = self.persons.data.find(person => person.id === id)
      }
      if (self.isDoctor) {
        person = self.persons.data.find(person => person.person.id === id)
      }
      if (person) {
        self.selected_person = person
        setTimeout(() => { self.selectedPerson() }, 50)
      }
    },
    async onAddNewPerson (data) {
      let self = this
      if (!this.can('create-new-patient')) return
      let user = appUtils.getLocalUser()
      let clinic = this.$globalClinic
      try {
        self.loading = true
        let resp = await self.$rf.getRequest(self.className).postPerson(data)
        console.log(data)
        self.sendAmplitude('Create Contact', {
          'doctor id': user && user.doctor && user.doctor.id ? user.doctor.id : '',
          'doctor name': user && user.name ? user.name : '',
          'workspace id': clinic && clinic.id ? clinic.id : '',
          'workspace name': clinic && clinic.name ? clinic.name : '',
          'timestamp': window.moment(new Date()).format('HH:mm DD-MM-YYYY')
          // 'patient id': data ? data : '',
          // 'patient name': ''
        })
        self.getDoctorPersons(null, resp.data.id)
        self.onShowModalAddPerson(false)
      } catch (e) {
        self.sendAmplitude('Error Create Contact', {
          'doctor id': user && user.doctor && user.doctor.id ? user.doctor.id : '',
          'doctor name': user && user.name ? user.name : '',
          'workspace id': clinic && clinic.id ? clinic.id : '',
          'workspace name': clinic && clinic.name ? clinic.name : '',
          'timestamp': window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
          // 'patient id': data ? data : '',
          // 'patient name': '',
          'message': e
        })
        console.log(e)
      } finally {
        self.add_person.name = ''
        self.add_person.phone = ''
        self.add_person.gender = ''
        self.add_person.cmnd = ''
        self.add_person.address = ''
        self.add_person.symptom = ''
        self.loading = false
      }
    },
    async getPersons (kw, person_id) {
      let self = this
      let params = {
        limit: 50,
        sort: 'created_at',
        sort_by: 'desc'
      }
      if (kw) {
        params.search = kw
      }
      try {
        let resp = await self.$rf.getRequest(self.className).getPersons(params)
        self.persons = resp.data
        if (person_id) {
          self.setDefaultPerson(person_id)
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async getDoctorPersons (kw, person_id) {
      let self = this
      let params = {
        limit: 50
      }
      if (kw) {
        params.search = kw
      }
      try {
        let resp = await self.$rf.getRequest('DoctorRequest').getDoctorPersons(params)
        self.persons = resp.data
        self.persons.data.forEach(person => {
          Object.assign(person, { name: person.person.name, phone: person.person.phone })
        })
        if (person_id) {
          self.setDefaultPerson(person_id)
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    searchPerson (search) {
      this.err_patient = null
      if (this.isDoctor) {
        this.getDoctorPersons(search)
      }
      if (this.isClinic) {
        this.getPersons(search)
      }
    },
    selectedPerson () {
      let self = this
      if (self.selected_person) {
        self.err_patient = ''
        if (self.isDoctor) {
          self.form.person_id = self.selected_person.person.id
        }
        if (self.isClinic) {
          self.form.person_id = self.selected_person.id
        }
      } else {
        self.form.person_id = ''
      }
    },
    formatDateTime (date_time) {
      return window.moment(date_time).format('HH:mm DD-MM-YYYY')
    },
    onShowModalAddPerson (show) {
      if (!this.can('create-new-patient')) return
      window.$('#modal__add_person').modal(show ? 'show' : 'hide')
    }
  }
}
