import appUtils from './appUtils'
import avatar from '../assets/images/avatar.png'

export const mixinPdfViewer = {
  methods: {
    async handleProcessDocument () {
      if (!this.envelopeInfo?.id) return

      try {
        let base64String
        if (this.isDocBase64) {
          base64String = this.removePrefix(this.urlPDF)
        } else {
          base64String = await this.getFileAsBase64(this.urlPDF)
        }

        const file = appUtils.convertBase64tToFile(
          base64String,
          'PdfViewer.pdf'
        )

        const params = { file }
        const formData = new FormData()
        for (const key in params) {
          formData.append(key, params[key])
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getSignaturesDetail(formData)

        const responseData = response?.data || []
        const formatResponse = responseData.map((item) => {
          this.removeParentheses(item)

          return {
            ...item,
            signature_index: this.getNumberFromSignature(item?.index_name),
            signed_at: this.convertDateFormat(item?.signed_at)
          }
        })
        this.signatureData = formatResponse

        return formatResponse

        // this.signed_at = response.data?.timestamp
        //   ? window
        //     .moment(response.data?.timestamp)
        //     .format('HH:mm:ss DD/MM/YYYY')
        //   : ''
      } catch (error) {
        console.log(error)
      }
    },
    generatePDFSrcBase64 (content) {
      const prefix = 'data:application/pdf;base64,'

      return prefix + content
    },
    removePrefix (base64String) {
      let prefix = 'data:image/png;base64,'
      if (base64String.startsWith(prefix)) {
        return base64String.slice(prefix.length)
      }
      return base64String
    },
    getAvatar (signatory) {
      if (!signatory?.user?.avatar) return avatar

      return appUtils.getImageURL(signatory?.user?.avatar)
    },
    async getFileAsBase64 (url) {
      try {
        // Fetch the file from the URL
        const response = await fetch(url)

        // Ensure the response is ok
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        // Get the Blob from the response
        const blob = await response.blob()

        // Convert the Blob to a Base64 string
        const base64String = await this.blobToBase64(blob)
        // const base64 = this.removePrefixDocument(base64String)

        return base64String
      } catch (error) {
        console.error('Error fetching or converting the file:', error)
      }
    },

    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },
    removePrefixDocument (base64) {
      if (!base64) return

      let prefix = 'data:application/pdf;base64,'
      if (base64.startsWith(prefix)) {
        return base64.slice(prefix.length)
      }
      return base64
    },
    getNumberFromSignature (signature) {
      if (!signature) return

      const prefix = 'Signature'
      if (signature?.startsWith(prefix)) {
        return Number(signature.slice(prefix.length))
      }
      return null
    },
    removeParentheses (obj) {
      if (!obj) return

      for (const key in obj) {
        if (typeof obj[key] === 'string') {
          obj[key] = obj[key].replace('(', '').replace(')', '')
        }
      }
    },

    convertDateFormat (dateStr) {
      if (!dateStr) return ''

      // Remove the "D:" at the start and "Z" at the end
      const trimmedDateStr = dateStr.slice(2, -1)

      // Parse the date
      const date = window.moment.utc(trimmedDateStr, 'YYYYMMDDHHmmss')
      date.add(7, 'hours')

      // Format the date
      const formattedDate = date.format('HH:mm:ss - DD/MM/YYYY')

      return formattedDate
    },
    handleCheckSignatories () {
      if (!this.envelopeInfo?.id) return

      this.signatories.forEach((signatory) => {
        const [signLLx, signLLy] =
                    signatory?.signatory_metadata?.signature_position_rectangle
                        ?.split(',')
                        ?.map((item) => parseInt(item))

        const signX = signLLx + this.imageX / 2
        const signY = signLLy + this.imageY / 2

        const imageDefault = document.getElementById(
          `${this.imageID}_${signatory?.id}_default`
        )

        if (imageDefault) {
          imageDefault.style.left = signX + 'px'
          imageDefault.style.top = signY + 'px'
        }
      })
    }
  }
}
