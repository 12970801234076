import PersonForm from '../components/PersonForm'
import appUtils from './appUtils'
export const mixinPersonAdd = {
  components: { PersonForm },
  computed: {
    className () {
      return appUtils.isClinic() ? 'ClinicRequest' : appUtils.isDoctor() ? 'DoctorRequest' : ''
    }
  },
  data () {
    return {
      add_birth: {
        day: '',
        month: '',
        year: ''
      },
      add_person: {
        name: '',
        phone: '',
        gender: '',
        cmnd: '',
        address: '',
        symptom: ''
      }
    }
  },
  methods: {
    async onAddNewPerson (data) {
      let self = this
      try {
        self.loading = true
        let resp = await self.$rf.getRequest(self.className).postPerson(data)
        self.sendAmplitude('Save New Patient', {
          'Status': 'Success',
          'Type': 'Modal',
          'Modal Name': 'Add Patient',
          'Screen': self.screen,
          'Root Screen': self.root_screen,
          'Timestamp': self.formatDateTime(new Date()),
          'Patient ID': resp.data.id,
          'Patient Name': resp.data.name ? resp.data.name : '',
          'Error': 'none'
        })
        if (data.symptom) {
          self.selected_person = resp.data
        }
        if (self.is_person_listing && !data.symptom) {
          window.$('.modal').modal('hide')
          self.$router.push({path: `/persons/${resp.data.id}`})
        }
        if (self.is_appointment_add && !data.symptom) {
          self.selected_person = resp.data
          self.form_appt.person_id = resp.data.id
          window.$('#modal__add_person').modal('hide')
          this.$emit('onShowModalAppt')
        }
        if (self.$router.currentRoute.name === 'VideoCallManagement') {
          self.selected_person = resp.data
          self.form.receiver_phone = resp.data.phone
          self.form.person_id = resp.data.id
          self.getPersons(10)
          window.$('#modal__add_person').modal('hide')
          window.$('#modal__create').modal('show')
        }
      } catch (e) {
        self.sendAmplitude('Save New Patient', {
          'Status': 'Fail',
          'Type': 'Modal',
          'Modal Name': 'Add Patient',
          'Screen': self.screen,
          'Root Screen': self.root_screen,
          'Timestamp': self.formatDateTime(new Date()),
          'Error': e
        })
        console.log(e)
      } finally {
        self.add_person.name = ''
        self.add_person.phone = ''
        self.add_person.gender = ''
        self.add_person.cmnd = ''
        self.add_person.address = ''
        self.add_person.symptom = ''
        self.loading = false
      }
    }
  }
}
