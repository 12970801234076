import Vue from 'vue'
const prototype = Vue.prototype

const uploadFileWithTenant = {
  async fileUploadHandler ({ files, path }) {
    if (!files || !files.length) return

    const self = this
    const fileResults = await Promise.all(
      files?.map(async (file) => {
        const res = await self.tenantResourcesUploader(path, file)
        return res
      })
    )

    return fileResults
  },
  async tenantResourcesUploader (path, file) {
    if (!file) return

    try {
      const params = {
        path,
        file
      }
      const formData = new FormData()

      for (var key in params) {
        formData.append(key, params[key])
      }

      const res = await prototype.$rf
        .getRequest('DoctorRequest')
        .uploadFileWithTenant(formData)
      if (!res?.data) return

      return res?.data
    } catch (error) {
      console.log(error)
    }
  }

}

export default uploadFileWithTenant
