export const mixinValidationForms = {
  data () {
    return {
      errorFields: {},
      isErrorValid: false,
      ignoreFields: []
    }
  },
  watch: {
    errorFields: {
      handler (data) {
        this.isErrorValid = this.setIsError(data)
      },
      deep: true
    }
  },
  methods: {
    handleResetErrors (data, errors, ignoreFields) {
      let formData = { ...data }

      if (ignoreFields && ignoreFields.length) {
        ignoreFields.map((field) => {
          delete formData[field]
        })
      }

      for (const key in formData) {
        errors[key] = false
      }

      return false
    },
    setIsError (errors) {
      let isError = false
      Object.entries(errors).map(([key, value]) => {
        if (value) {
          isError = true
        }
      })
      return isError
    },
    handleValidationForms (data, errors, ignoreFields) {
      if (!data) return

      const self = this

      self.handleResetErrors(data, errors, ignoreFields)

      let formData = { ...data }

      if (ignoreFields && ignoreFields.length) {
        ignoreFields.map((field) => {
          delete formData[field]
        })
      }

      const phoneRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g

      // eslint-disable-next-line no-useless-escape
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      for (const key in formData) {
        if (formData[key] === '') {
          errors[key] = self.$t('workspace_setting.lbl_cannot_empty')
        }

        if (
          key === 'phone' &&
          formData[key] !== '' &&
          !phoneRegex.test(formData[key])
        ) {
          errors[key] = self.$t('sign_up.message_errors.phone_invalidate')
        }

        if (
          key === 'email' &&
          formData[key] !== '' &&
          !emailRegex.test(formData[key])
        ) {
          errors[key] = self.$t('sign_up.message_errors.email_invalidate')
        }
      }

      return self.setIsError(errors)
    }
  }
}
