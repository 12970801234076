import appUtils from './appUtils.js'
const rolesPermissions = {
  setRole: role => localStorage.setItem('roles', JSON.stringify(role)),
  rmRole: () => localStorage.removeItem('roles'),
  getRole: () => localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')) : null,
  setPermissionsList: permissions => localStorage.setItem('list_permissions', JSON.stringify(permissions)),
  removePermissionsList: () => localStorage.removeItem('list_permissions'),
  getPermissionsList: () => localStorage.getItem('list_permissions') ? JSON.parse(localStorage.getItem('list_permissions')) : null,
  isHasPermission (slug) {
    let permissions = this.getPermissionsList()
    const wsp = this.$globalClinic
    const user = appUtils.getLocalUser()
    if (wsp && wsp.doctor_id && user && user.doctor && wsp.doctor_id === user.doctor.id) {
      return true
    } else {
      if (!permissions || !permissions.length) {
        return false
      } else {
        return permissions.findIndex(pms => pms.slug === slug) > -1
      }
    }
  }
}

export default rolesPermissions
