import { BMHC_OPTIONS } from '@/utils/constants'
export const mixinAdministrativeForm = {
  data () {
    return {}
  },
  methods: {
    handleGetTitle (dataType) {
      const bmhcType = Number(dataType)
      if (bmhcType === BMHC_OPTIONS.xnHIV) {
        return 'Đơn xin thực hiện dịch vụ kĩ thuật xét nghiệm HIV/HBV/HCV'
      }
      if (bmhcType === BMHC_OPTIONS.giayXinRaVien) {
        return 'Đơn xin ra viện'
      }
      if (bmhcType === BMHC_OPTIONS.dungCuYTe) {
        return 'Đơn xin sử dụng dịch vụ y tế theo yêu cầu'
      }
      if (bmhcType === BMHC_OPTIONS.kiThuatXN) {
        return 'Đơn xin thực hiện dịch vụ kĩ thuật xét nghiệm'
      }
      if (bmhcType === BMHC_OPTIONS.camKetPTTT) {
        return 'Giấy cam đoan chấp nhận phẫu thuật, thủ thuật, phương pháp phẫu thuật/thủ thuật'
      }
      if (bmhcType === BMHC_OPTIONS.dungCuPhauThuat) {
        return 'Đơn xin sử dụng dụng cụ phẫu thuật theo yêu cầu'
      }
      if (bmhcType === BMHC_OPTIONS.khamTimMach) {
        return 'Đơn xin khám chuyên khoa tim mạch theo yêu cầu'
      }
      if (bmhcType === BMHC_OPTIONS.dieuTriTuNguyen) {
        return 'Đơn xin nằm giường điều trị tự nguyện'
      }
      if (bmhcType === BMHC_OPTIONS.suDungThuoc) {
        return 'Đơn xin sử dụng thuốc đồng chi trả/ngoài chi trả BHYT'
      }
      if (bmhcType === BMHC_OPTIONS.tienSuNguoiBenh) {
        return 'Giấy khai thác tiền sử người bệnh và chấp nhận chụp cộng hưởng từ'
      }
      if (bmhcType === BMHC_OPTIONS.camKetHoaChat) {
        return 'Giấy cam đoan chấp nhận điều trị hoá chất'
      }
      if (bmhcType === BMHC_OPTIONS.donXinSaoLuuBenhAn) {
        return 'Đơn xin sao lưu hồ sơ bệnh án, kết quả cận lâm sàng'
      }
      if (bmhcType === BMHC_OPTIONS.dieuTriBangMayGiaToc) {
        return 'Đơn xin điều trị bằng máy gia tốc'
      }
      if (bmhcType === BMHC_OPTIONS.chapNhanXaTri) {
        return 'Giấy cam đoan chấp nhận xạ trị'
      }
      if (bmhcType === BMHC_OPTIONS.giayRaVien) {
        return 'Giấy ra viện'
      }
      if (bmhcType === BMHC_OPTIONS.giayChungNhanPhauThuat) {
        return 'Giấy chứng nhận phẫu thuật'
      }
    }
  }
}
