import appUtils from './appUtils'
import e3kit from '@/libs/e3kit'
import { mapActions } from 'vuex'

export const mixinGoToChat = {
  data () {
    return {
      circle: null,
      selectedMember: null,
      channelId: null,
      channel: null,
      userIdsNotRegister: [],
      appUtils
    }
  },
  methods: {
    ...mapActions('app', ['refreshE3kitToken']),
    async setSelectedMember (data) {
      this.selectedMember = { ...data }
    },
    async setSelectedCircle (data) {
      this.circle = { ...data }
    },
    async messageTo (id, e) {
      let self = this
      self.loading = true
      let tg = e.currentTarget
      tg.classList.add('loading-btn')

      if (self.channelId) {
        self.$reloadChat++
        await self.handleCreateNewConversation()
        tg.classList.remove('loading-btn')
        return
      }

      await self.$rf
        .getRequest('AuthRequest')
        .directChatTo({ partner_user_id: id }, null, false)
        .then(async (r) => {
          self.$reloadChat++
          if (r?.data?.twilio_conv_id) {
            self.channelId = r?.data?.twilio_conv_id
            await self.handleGetChannel(r?.data?.twilio_conv_id)
            await self.handleCreateNewConversation()
          }
        })
        .finally(() => {
          tg.classList.remove('loading-btn')
        })
    },
    async messageToGroup (twilio_conv_id, e) {
      await this.handleGetChannel(twilio_conv_id)
      await this.handleCreateNewConversation()
    },
    async handleGetChannel (sid) {
      const channelIDRaw = sid || this.channelId

      if (!channelIDRaw) return

      this.channelId = channelIDRaw

      try {
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getChannel(channelIDRaw)

        if (res.data?.code === 200001) {
          this.channel = null
          return
        }

        this.channel = res.data
      } catch (error) {
        console.log(error)
      }
    },
    async handleCreateNewConversation (isNextMigrate = false) {
      if (!this.$e3kit || !e3kit || !this.channelId) {
        return
      }

      const data = {
        twillio_channel_id: this.channelId,
        next_migrate: isNextMigrate
      }

      try {
        if (this.channel && this.channel.cid) {
          this.goToConversation()
          return
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .createNewChannel(data)

        if (res.data?.code === 200032) {
          this.$message({
            type: 'error',
            message: `Không tìm thấy thông tin của ${this.selectedMember?.name}.`
          })
          return
        }

        const ownerId = String(this.$user.id)
        const channelCId = res.data?.migrate_channel_get_streams?.cid
        const memberIds = (
          !isNextMigrate ? res.data?.user_members : res.data?.user_migrated
        )
          ?.map((m) => m?.id?.toString())
          ?.sort()

        if (memberIds.length === 1) {
          this.$message({
            type: 'error',
            message: `Không tìm thấy thông tin của ${this.selectedMember?.name}.`
          })
          return
        }

        const participantIds = Object.values(memberIds || {}).filter(
          (m) => m !== ownerId
        )

        this.userIdsNotRegister = !isNextMigrate
          ? [...(res.data?.user_not_migrate || [])]
          : []

        if (this.userIdsNotRegister.length && !isNextMigrate) {
          this.$message({
            type: 'error',
            message: `Không thể Chat do ${this.selectedMember?.name} đã lâu chưa Online.`
          })
          return
        }

        if (!channelCId) {
          this.$message({
            type: 'error',
            message: 'Không tìm thấy Channel ID.'
          })
          return
        }

        this.channel = res.data?.migrate_channel_get_streams

        const groupId = `group_${channelCId}`

        // await e3kit.deleteGroup(groupId)
        const group = await e3kit.createGroup(groupId, participantIds, ownerId)

        if (group) {
          this.goToConversation()
        } else {
          this.$message({
            type: 'warning',
            message: `Không thể Chat với ${this.selectedMember?.name}. Vui lòng liên hệ với ADMIN để xử lý.`
          })
        }
      } catch (error) {
        if (error.message === 'JWT is expired') {
          // Refresh Encryption Token
          await this.handleDeleteChannel()
          await this.refreshE3kitToken()
          this.userIdsNotRegister = []
          await this.handleCreateNewConversation()
        }
      } finally {
        this.clearData()
      }
    },
    async goToConversation () {
      const domain =
        window.location.hostname === 'localhost'
          ? 'http://localhost:3000'
          : process.env.VUE_APP_HODO_CONVERSATION
      const routeRaw = `${domain}/redirect.html?token=${appUtils.getLocalToken()}&lang=${
        this.$lang
      }&channel_id=${this.channel?.channel_id}`

      this.clearData()

      if (appUtils.checkIsMobile()) {
        let linkElement = document.createElement('a')
        const linkId = `conversation_id_${appUtils.randomStr(6, true)}`
        linkElement.id = linkId
        linkElement.href = routeRaw
        window.document.body.appendChild(linkElement)

        const linkEl = document.getElementById(linkId)
        linkEl.setAttribute('rel', 'noopener noreferrer')
        !appUtils.checkIsMobile() && linkEl.setAttribute('target', 'blank')
        linkEl.click()
        linkEl.remove()
        return
      }

      window.open(routeRaw, 'blank')
    },
    async handleDeleteChannel () {
      if (!this.channelId) return

      try {
        const data = {
          twilio_conv_id: this.channelId
        }
        await this.$rf.getRequest('DoctorRequest').deleteChannel(data)
      } catch (error) {
        console.log(error)
      }
    },
    clearData () {
      this.circle = null
      this.selectedMember = null
      this.channelId = null
      this.channel = null
      this.userIdsNotRegister = []
    }
  }
}
