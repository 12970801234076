import Vue from 'vue'
import appUtils from '../utils/appUtils'
import orgRole from '../constants/orgRole.js'

Vue.mixin({
  methods: {
    sendAmplitude (event, event_properties) {
      let self = this
      let user = appUtils.getLocalUser()
      if (!window.localStorage.isAdmin) {
        if (user) {
          window.amplitude.setUserId(user.id)
          window.amplitude.setUserProperties({
            'User Name': user ? user.name : '',
            'User Type': user ? self.getRoles(user.roles) : '',
            'Clinic ID': user && user.clinic ? user.clinic.id : '',
            'Doctor ID': user && user.doctor ? user.doctor.id : '',
            'Clinic Name': user && user.clinic ? user.clinic.name : '',
            'Doctor Name': user && user.doctor ? user.name : ''
          })
        } else {
          window.amplitude.clearUserProperties()
        }
        window.amplitude.logEvent(event, event_properties)
      }
    },
    getRoles (roles) {
      let list_role = ''
      if (roles) {
        roles.forEach(function (role, i) {
          if (i === 0) {
            list_role += role
          } else {
            list_role += ',' + role
          }
        })
      }
      return list_role
    },
    canModifyMeet (members) {
      let user = this.$user
      if (!user || !members?.length) return false
      let member = members?.some((m) => m.user_id === user?.id)
      return member
    },
    checkRoleOwnerOrg (org) {
      let user = this.$user
      if (!user) return false
      const orgRawInMemberOrgUser = user?.members?.find(
        (o) => o?.org_id === org?.id || o?.org_id === org?.parent_id
      )
      let owner = org.owner_id === user?.id || Boolean(orgRawInMemberOrgUser)
      let isOwnerOrAdmin = [orgRole.Owner, orgRole.Admin].some((el) =>
        org.members?.map((m) => m?.role).includes(el)
      )
      return owner || isOwnerOrAdmin
    }
  }
})
