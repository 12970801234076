export const mixinNotification = {
  methods: {
    async goToNotification (noti) {
      let self = this
      let now = window.moment().unix()
      if (noti) {
        if (noti.type.indexOf('NewAppointmentPatientNotify') > -1 || noti.type.indexOf('SendIndicationCheckinNotify') > -1) {
          let path = self.isDoctor ? '/doctor/appointment/' + noti.data.appt_id : '/appointment/' + noti.data.appt_id + `?t=${now}`
          self.$router.push({ path: path }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('NextAppointmentNotify') > -1) {
          let path = '/doctor/appointment/' + noti.data.appt_id + `?t=${now}`
          self.$router.push({ path: path }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('NewFriendRequestSendNotify') > -1 || noti.type.indexOf('RejectFriendRequestSendNotify') > -1) {
          self.$router.push({ path: '/doctor/circle', query: { type: 'circle-invites', t: now } }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('PatientSendRequestToDoctorNotify') > -1 || noti.type.indexOf('RequestPatientRejectNotify') > -1 || noti.type.indexOf('RequestTransferedNotify') > -1) {
          self.$router.push({ path: '/doctor/request-detail/' + noti.data.request_id + '/info' + `?t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('NewAppointmentDoctorNotify') > -1) {
          self.$router.push({ path: `/doctor/appointment-management?date=today&type=upcoming&t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('CampaignNotify') > -1) {
          if (noti && noti.data && noti.data.url) {
            location.href = noti.data.url
          }
        }
        if (noti.type.indexOf('RequestDoctorRejectNotify') > -1) {
          self.$router.push({ path: '/doctor/request-detail/' + noti.data.request_id + '/info' + `?t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('TicketPatientFeedbackNotify') > -1 || noti.type.indexOf('TicketPatientRejectNotify') > -1) {
          self.$router.push({ path: '/doctor/request-detail/' + noti.data.request_id + '/ticket' + `?t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('AppointmentPatientCancelNotify') > -1) {
          self.$router.push({ path: '/doctor/appointment/' + noti.data.appt_id + `?t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('InvoicePatientPaidNotify') > -1) {
          self.$router.push({ path: `/doctor/invoice/${noti.data.invoice_id}?token=${noti.data.invoice_token}&t=${now}` }).catch(_ => { this.$router.go() })
          return false
        }
        if (noti.type.indexOf('AppointmentPatientAcceptNotify') > -1) {
          self.$router.push({ path: `/doctor/appointment/${noti.data.appt_id}?t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('CarePlanInviteDoctorNotify') > -1) {
          self.$router.push({ path: `/doctor/waiting-list?t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('CarePlanHasNewSymptomNotify') > -1 || noti.type.indexOf('PatientOverSurvivalNotify') > -1) {
          self.$router.push({ path: `/doctor/diseases/${noti.data.person_diseases_id}/health-information?t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('CarePlanHasNewDocumentNotify') > -1) {
          self.$router.push({ path: `/doctor/diseases/${noti.data.person_diseases_id}/document-management?t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('PersonPostTopicCommentNotify') > -1) {
          self.$router.push({ path: `/doctor/diseases/${noti.data.person_diseases_id}/topic/${noti.data.topic_id}?t=${now}` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('EmrIndicationResultNotify') > -1) {
          self.$router.push({ name: 'ERMTreeView', params: { id: noti.data.medical_record_id }, query: { tab: parseInt(noti.data.emr_indication_type) === 1 ? 'CLS' : 'ChanDoanHinhAnh', viewId: noti.data.emr_indication_id, t: now } }).catch(_ => { this.$router.go() })
        }
        setTimeout(() => {
          self.sendAmplitude('Open Notification Detail', {
            'doctor id': self.doctor && self.doctor.doctor && self.doctor.doctor.id ? self.doctor.doctor.id : '',
            'doctor name': self.doctor && self.doctor.name ? self.doctor.name : '',
            'workspace id': self.clinic && self.clinic.id ? self.clinic.id : '',
            'workspace name': self.clinic && self.clinic.name ? self.clinic.name : '',
            'timestamp': window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
            'request id': noti.id ? noti.id : ''
          })
        }, 100)
      }
    },
    async getNotification (id) {
      let self = this
      await self.$rf.getRequest('DoctorRequest').getNotificationDetail(id).then((resp) => {
        if (resp.data) {
          self.goToNotification(resp.data)
        }
      })
    }
  }
}
