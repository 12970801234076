import appUtils from './appUtils'
import html2canvas from 'html2canvas'
import uploadImagePrint from './uploadImagePrint'

export const mixinPrint = {
  methods: {
    onPrint () {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML

      // Get all stylesheets HTML
      let stylesHtml = ''
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML
      }

      // Open the print window
      const { width, height } = window.screen
      const WinPrint = window.open('', '', `left=0,top=0,width=${width},height=${height},toolbar=0,scrollbars=0,status=0`)

      WinPrint.document.write(`<!DOCTYPE html><html><head>${stylesHtml}</head><body>${prtHtml}</body></html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      // WinPrint.close()
    },
    onPrint2 (path) {
      // Open the print window
      const { width, height } = window.screen
      const WinPrint = window.open(window.location.origin + '/' + path, '', `left=0,top=0,width=${width},height=${height},toolbar=0,scrollbars=0,status=0`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      // WinPrint.close()
    },
    onPrintTBBHC (prtHtml, print = false) {
      // Open the print window
      const { width, height } = window.screen
      const WinPrint = window.open('', '', `left=0,top=0,width=${width},height=${height},toolbar=0,scrollbars=0,status=0`)

      WinPrint.document.write(prtHtml)

      const el = WinPrint.document.documentElement
      // console.log(el, prtHtml)

      if (print) {
        WinPrint.focus()
        WinPrint.print()
      } else {
        WinPrint.blur()
        window.focus()
      }
      WinPrint.close()
      return el
    },
    async savePdfToS3 ({ base64, fileName = 'DocumentFile', path = 'documents', type = 'documents', type_id = 'documents_id', uuid = '', isOpenLink = true }) {
      if (!base64) {
        console.log('Base64 content not found')
        return
      }

      try {
        const payload = {
          content: base64,
          fileName,
          path,
          type,
          type_id,
          uuid
        }

        const res = await this.$rf.getRequest('DoctorRequest').printDocument(payload)

        if (res?.data && isOpenLink) {
          window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
        this.loading = false
        return res.data?.documentLink
      } catch (error) {
        console.log(error)
      }
    },
    async captureImageCanvas ({ doc, dpi = 2000, scale = 10 }) {
      if (!doc || typeof doc === 'undefined') {
        console.log('Refs document print not found')
        return
      }

      try {
        const options = {
          dpi,
          scale,
          backgroundColor: '#ffffff',
          image: { type: 'jpeg', quality: 1 },
          letterRendering: true,
          allowTaint: true,
          logging: true,
          useCORS: true
        }
        const dataCanvas = await html2canvas(doc, options)
          .then(canvas => {
            const imgBase64Data = canvas.toDataURL('image/jpeg')
            return imgBase64Data
          })
          .catch(() => {})

        return dataCanvas
      } catch (error) {
        console.log(error)
      }
    },
    async exportPdf ({ refDocument, fileName, path, type, type_id, uuid, appendStyle }, isOpenLink = true) {
      if (!refDocument) {
        console.log('Refs document print not found')
        return
      }

      try {
        const bodyHTML = refDocument.innerHTML
        const tempHTML = appUtils.templateHTMLPrint({ bodyHTML, appendStyle })
        const base64Data = appUtils.base64.decode(tempHTML)

        const link = await this.savePdfToS3({
          base64: base64Data,
          fileName,
          path,
          type,
          type_id,
          uuid,
          isOpenLink
        })

        return link
      } catch (error) {
        console.log(error)
      }
    },
    async exportPdfCaptureImage ({ refDocument, fileName, path, type, type_id, uuid }) {
      if (!refDocument || typeof refDocument === 'undefined') {
        console.log('Refs document print not found')
        return
      }

      try {
        await Promise.all(
          refDocument?.map(async doc => {
            const imgBase64Data = await this.captureImageCanvas({
              doc,
              dpi: 2000,
              scale: 10
            })

            const res = await uploadImagePrint.fileUploadHandler(imgBase64Data)

            const wrap = document.createElement('div')
            const div = document.createElement('div')
            const img = document.createElement('img')

            img.src = res
            img.style.maxWidth = '100%'
            div.appendChild(img)
            wrap.appendChild(div)

            return wrap.innerHTML
          })
        ).then(async res => {
          let htmlRaw = ''

          console.log(res)

          // eslint-disable-next-line no-unused-expressions
          res?.map((img, index) => {
            if (index < res.length - 1) {
              htmlRaw += `${img}<div class="html2pdf__page-break"></div>`
            } else {
              htmlRaw += img
            }
          })

          const tempHTML = appUtils.templateHTMLPrint({
            bodyHTML: `<div class="hodo-print">${htmlRaw}</div>`
          })
          const base64Data = appUtils.base64.decode(tempHTML)

          console.log(tempHTML)

          await this.savePdfToS3({
            base64: base64Data,
            fileName,
            path,
            type,
            type_id,
            uuid
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async exportPdfWithIframe ({ refDocument, fileName, path, type, type_id, uuid }) {
      if (!refDocument) {
        console.log('Refs document print not found')
        return
      }

      try {
        const iframeData = document.getElementById('iframeData')

        if (iframeData) {
          iframeData.remove()
        }

        const iframe = document.createElement('iframe')
        const head = document.head
        const body = refDocument
        let domain = window.location.origin
        if (domain.includes('localhost')) {
          domain = 'https://dev.hodo.app'
        }
        const link_1 = document.createElement('link')
        link_1.href = `${domain}/assets/tabler/dist/css/tabler.min.css`
        link_1.type = 'text/css'
        link_1.rel = 'stylesheet'
        const link_2 = document.createElement('link')
        link_2.href = `${domain}/assets/css/print.css`
        link_2.type = 'text/css'
        link_2.rel = 'stylesheet'
        const link_3 = document.createElement('link')
        link_3.href = `${domain}/assets/css/style.css`
        link_3.type = 'text/css'
        link_3.rel = 'stylesheet'
        const link_4 = document.createElement('link')
        link_4.href = 'https://use.fontawesome.com/releases/v5.8.1/css/all.css'
        link_4.type = 'text/css'
        link_4.rel = 'stylesheet'

        iframe.id = 'iframeData'
        iframe.width = 715
        iframe.height = 1080
        iframe.style.background = 'white'
        // iframe.style.opacity = 0
        iframe.style.pointerEvents = 'none'
        iframe.style.border = 'none'
        // iframe.style.overflow = 'hidden'

        document.body.appendChild(iframe)

        const elIframe = document.getElementById('iframeData')
        const docIframe = elIframe.contentWindow.document
        const docIframeHead = docIframe.getElementsByTagName('head')[0]
        const docIframeBody = docIframe.getElementsByTagName('body')[0]

        const removeNodes = node => {
          while (node.firstChild) {
            node.removeChild(node.firstChild)
          }
        }

        const appendNodes = (iframe1Node, iframe2Node, isBody) => {
          var child = iframe1Node.firstChild

          if (isBody) {
            while (child) {
              if (child.nodeType === Node.ELEMENT_NODE) {
                if (child.nodeName === 'SCRIPT') {
                  var script = docIframe.createElement('script')
                  script.type = child.type
                  script.src = child.src

                  iframe2Node.appendChild(script)
                } else {
                  iframe2Node.appendChild(docIframe.importNode(child, true))
                }
              }

              child = child.nextSibling
            }
          } else {
            while (child) {
              if (child.nodeType === Node.ELEMENT_NODE) {
                if (child.nodeName === 'STYLE') {
                  iframe2Node.appendChild(docIframe.importNode(child, true))
                }
              }

              child = child.nextSibling
            }
          }
        }

        removeNodes(docIframeHead)
        removeNodes(docIframeBody)

        docIframeHead.appendChild(link_1)
        docIframeHead.appendChild(link_2)
        docIframeHead.appendChild(link_3)
        docIframeHead.appendChild(link_4)

        appendNodes(head, docIframeHead)
        appendNodes(body, docIframeBody, true)

        const htmlDoc = `<html>${docIframe.documentElement.innerHTML}</html>`
        const base64Data = appUtils.base64.decode(htmlDoc)

        console.log(htmlDoc)

        // elIframe.remove()

        await this.savePdfToS3({
          base64: base64Data,
          fileName,
          path,
          type,
          type_id,
          uuid
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
