export const TQM_TYPE = 1
export const TQM_PASS = 2
export const TQM_NOT_PASS = 3
export const STATUS_SCHEDULE = {
  CODE: {
    PENDING: 0,
    DONE: 1,
    CANCEL: 2
  },
  NAME: {
    PENDING: 'PENDING',
    DONE: 'DONE',
    CANCEL: 'CANCEL'
  }
}

export const EXAMINATION_STATUS = {
  CODE: {
    WAITING: 1,
    BEING: 2,
    WAITING_RESULT: 3,
    WAITING_READ_RESULT: 4,
    DONE: 5,
    CANCELED: 6,
    NEXT_CALL: 7,
    MISSING: 8
  },
  NAME: {
    WAITING: 'WAITING',
    BEING: 'BEING',
    WAITING_RESULT: 'WAITING_RESULT',
    WAITING_READ_RESULT: 'WAITING_READ_RESULT',
    DONE: 'DONE',
    CANCELED: 'CANCELED',
    NEXT_CALL: 'NEXT_CALL',
    MISSING: 'MISSING'
  },
  ID: {
    1: 'WAITING',
    2: 'BEING',
    3: 'WAITING_RESULT',
    4: 'WAITING_READ_RESULT',
    5: 'DONE',
    6: 'CANCELED',
    7: 'NEXT_CALL',
    8: 'MISSING'
  }
}

export const APPOINTMENT_STATUS = {
  CODE: {
    CLINIC_PENDING: 1,
    CLINIC_CONFIRMED: 2,
    CLINIC_REJECTED: 3,
    PATIENT_PENDING: 4,
    PATIENT_ACCEPTED: 5,
    PATIENT_CANCELLED: 6
  },
  NAME: {
    CLINIC_PENDING: 'CLINIC_PENDING',
    CLINIC_CONFIRMED: 'CLINIC_CONFIRMED',
    CLINIC_REJECTED: 'CLINIC_REJECTED',
    PATIENT_PENDING: 'PATIENT_PENDING',
    PATIENT_ACCEPTED: 'PATIENT_ACCEPTED',
    PATIENT_CANCELLED: 'PATIENT_CANCELLED'
  },
  ID: {
    1: 'CLINIC_PENDING',
    2: 'CLINIC_CONFIRMED',
    3: 'CLINIC_REJECTED',
    4: 'PATIENT_PENDING',
    5: 'PATIENT_ACCEPTED',
    6: 'PATIENT_CANCELLED'
  }
}

export const APPOINTMENT_METHOD_STATUS = {
  CODE: {
    OFFLINE: 1,
    ONLINE: 2
  },
  NAME: {
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE'
  },
  ID: {
    1: 'OFFLINE',
    2: 'ONLINE'
  }
}

export const INVOICE_STATUS = {
  CODE: {
    PATIENT_PENDING: 1,
    PATIENT_PAID: 2,
    DOCTOR_ACCEPT: 3,
    PAID_IN_FRONT_DESK: 4
  },
  NAME: {
    PATIENT_PENDING: 1,
    PATIENT_PAID: 2,
    DOCTOR_ACCEPT: 3,
    PAID_IN_FRONT_DESK: 4
  },
  ID: {
    1: 'PATIENT_PENDING',
    2: 'PATIENT_PAID',
    3: 'DOCTOR_ACCEPT',
    4: 'PAID_IN_FRONT_DESK'
  }
}
export const ROOM_STATUS = {
  CODE: {
    AVAILABLE: 1,
    OCCUPIED: 2,
    ON_SERVICE: 3
  },
  NAME: {
    AVAILABLE: 1,
    OCCUPIED: 2,
    ON_SERVICE: 3
  },
  ID: {
    1: 'AVAILABLE',
    2: 'OCCUPIED',
    3: 'ON_SERVICE'
  }
}
export const PATIENT_RECEPTION_TYPE = {
  CODE: {
    APPOINTMENT: 1,
    WAITING_EXAMINATION: 2
  },
  NAME: {
    APPOINTMENT: 'APPOINTMENT',
    WAITING_EXAMINATION: 'WAITING_EXAMINATION'
  },
  ID: {
    1: 'APPOINTMENT',
    2: 'WAITING_EXAMINATION'
  }
}

export const INDICATION_TYPES = {
  CODE: {
    TEST_FORM: 1,
    ULTRASOUND: 2,
    OTHERS: 3,
    MOLECULAR_BIOLOGY: 4
  }
}

export const INDICATION_STATUS = {
  CODE: {
    PENDING: 0,
    HAS_RESULT: 1,
    CS: 2,
    NCS: 3
  }
}

export const DOCUMENT_SIGN_TYPES = {
  CODE: {
    CONSULTATION: 1,
    INDICATION: 2,
    PRESCRIPTION: 3,
    INDICATION_ORDER: 4,
    DISEASE_OPERATION: 5
  }
}

export const DEFAULT_SIZE_OF_PAGE = 7

export const APP_MODE = {
  workspace: 1,
  organization: 2
}

export const IS_OUT_PATIENT_TYPE = {
  YES: 1,
  NO: 2
}

export const MENU_EMR_TEMPLATE = [
  {
    label: 'Bệnh án vào viện',
    value: '1'
  },
  {
    label: 'Biên bản hội chẩn',
    value: '2'
  },
  {
    label: 'Tóm tắt thông qua mổ',
    value: '3'
  },
  {
    label: 'Tờ điều trị',
    value: '4'
  },
  {
    label: 'Phiếu chăm sóc',
    value: '5'
  },
  {
    label: 'Phiếu phẫu thuật/ thủ thuật',
    value: '6'
  },
  {
    label: 'Phiếu Y lệnh truyền',
    value: '7'
  }
]

export const SERVICE_GROUP = [
  {
    label: 'Xét nghiệm',
    value: 1
  },
  {
    label: 'Chẩn đoán hình ảnh',
    value: 2
  },
  {
    label: 'Chỉ định khác',
    value: 3
  },
  {
    label: 'Sinh học phân tử',
    value: 4
  },
  {
    label: 'Khám tư vấn',
    value: 5
  }
]

export const LIST_BMHC_DISEASE = [15, 16]

export const BMHC_OPTIONS = {
  xnHIV: 1,
  giayXinRaVien: 2,
  dungCuYTe: 3,
  kiThuatXN: 4,
  camKetPTTT: 5,
  dungCuPhauThuat: 6,
  khamTimMach: 7,
  dieuTriTuNguyen: 8,
  suDungThuoc: 9,
  tienSuNguoiBenh: 10,
  camKetHoaChat: 11,
  donXinSaoLuuBenhAn: 12,
  dieuTriBangMayGiaToc: 13,
  chapNhanXaTri: 14,
  giayRaVien: 15,
  giayChungNhanPhauThuat: 16
}

export const CIRCLE_TYPE = {
  NORMAL: 1,
  LIVE: 2
}

export const UNIT_OPTIONS = [
  {
    value: 32,
    text: 'Viên'
  },
  {
    value: 30,
    text: 'Tuýp'
  },
  {
    value: 29,
    text: 'Túi/chai/lọ'
  }
]
export const ACTIVE_INGREDIENT_OPTIONS = [
  {
    value: 1,
    text: 'Atropin sulfat'
  },
  {
    value: 2,
    text: 'Bupivacain (hydroclorid)'
  },
  {
    value: 3,
    text: 'Dexmedetomidin'
  }
]

export const ROUTE_OF_USE = [
  {
    value: 1,
    text: 'Uống'
  },
  {
    value: 2,
    text: 'Ngậm'
  },
  {
    value: 3,
    text: 'Đặt dưới lưỡi'
  }
]

export const RESERVATION_TYPE = {
  CODE: {
    AVAILABLE: 1,
    MAINTANCE: 2
  }
}
export const RESERVATION_STATUS = {
  CODE: {
    NOT_RECEIVED: 1,
    RECEIVED: 2,
    CANCELLED: 3,
    COMPLETED: 4
  }
}
export const CONFERENCE_PAYMENT = {
  unPaid: 1,
  paid: 2
}

export const CONFERENCE_MEMBERS_TYPE = {
  admin: 1,
  member: 2,
  patient: 3,
  chair: 4,
  reporter: 5
}
export const CONFERENCE_LIST_MEMBERS_TYPE = {
  member: 1,
  reporter: 2
}

export const ADDRESS_TYPE = {
  province: 'province',
  district: 'district',
  ward: 'ward'
}

export const HODO_PACKAGE = {
  standard: {
    label: 'Standard',
    value: 1
  },
  plus: {
    label: 'Plus',
    value: 2
  },
  pro: {
    label: 'Pro',
    value: 3
  }
}

export const SYNC_HIS = {
  notSync: 1,
  sync: 2
}

export const PATIENT_SYNC_HIS = {
  notNew: 1,
  isNew: 2
}

export const CHAT_CONVERSATION_TYPE = {
  GROUP: 2,
  DIRECT: 4
}

export const DEFAULT_CONFERENCE_ID = 1

export const PATIENT_SOURCE_TYPE = {
  HODO: 1,
  HIS: 2
}

export const HODO_CREDIT_TYPE = {
  accumulate: 1,
  redemption: 2,
  deposit: 3
}

export const DEPOSIT_SCOPE = {
  org: 1,
  ws: 2
}

export const SERVICE_TYPE = [
  {
    label: 'Xét nghiệm',
    value: 1
  },
  {
    label: 'Khám trực tiếp',
    value: 2
  },
  {
    label: 'Khám tư vấn từ xa',
    value: 3
  }
]

export const USE_ROOM_PRICE_CR = {
  isNotUse: 1,
  isUse: 2
}

export const TRANSACTION_SLIP_STATUS = {
  unPaid: 1,
  paid: 2
}

export const PAYMENT_TRANSACTION_TYPE = {
  indication: 1,
  homeDoctor: 2,
  examination: 3,
  consultation: 4
}

export const TRANSACTION_ERROR = [
  { value: '1', label: 'lbl_the_bank_refused_to_authorize_the_transaction_' },
  {
    value: '2',
    label: 'lbl_the_card_issuing_bank_refuses_to_authorize_the_transaction_'
  },
  {
    value: '3',
    label: 'lbl_no_response_has_been_received_from_the_card_issuer_'
  },
  {
    value: '4',
    label: 'lbl_the_card_s_expiration_month_year_is_incorrect_or_the_card_has_expired_'
  },
  { value: '5', label: 'lbl_card_balance_limit_is_not_enough_for_payment_' },
  {
    value: '6',
    label: 'lbl_no_response_has_been_received_from_the_card_issuer_'
  },
  {
    value: '7',
    label: 'lbl_error_in_the_bank_s_transaction_processing_process_'
  },
  {
    value: '8',
    label: 'lbl_the_card_issuing_bank_does_not_support_online_payments_'
  },
  { value: '9', label: 'lbl_invalid_cardholder_account_name_' },
  { value: '10', label: 'lbl_expired_card_card_locked_' },
  {
    value: '11',
    label: 'lbl_card_account_has_not_registered_for_online_payment_support_service_'
  },
  {
    value: '12',
    label: 'lbl_month_year_of_issuance_or_expiration_of_the_card_is_invalid_'
  },
  {
    value: '13',
    label: 'lbl_transactions_exceeding_the_online_payment_limit_according_to_the_bank_s_regulations_'
  },
  { value: '14', label: 'lbl_invalid_card_number_' },
  { value: '21', label: 'lbl_account_balance_is_not_enough_for_payment_' },
  { value: '22', label: 'lbl_invalid_account_information_' },
  { value: '23', label: 'lbl_card_account_is_locked_or_not_activated_' },
  { value: '24', label: 'lbl_invalid_card_account_information_' },
  { value: '25', label: 'lbl_otp_authentication_code_is_invalid_' },
  { value: '26', label: 'lbl_the_otp_authentication_code_has_expired_' },
  { value: '98', label: 'lbl_transaction_validation_is_cancelled' },
  { value: '99', label: 'lbl_user_cancels_transaction_' },
  {
    value: 'B',
    label: 'lbl_error_during_transaction_authentication_by_the_card_issuing_bank_'
  },
  {
    value: 'D',
    label: 'lbl_error_during_transaction_authentication_by_the_card_issuing_bank_'
  },
  { value: 'F', label: 'lbl_csc_code_validation_failed_' },
  { value: 'U', label: 'lbl_transaction_validation_failed_' },
  { value: 'Z', label: 'lbl_transaction_declined_' },
  {
    value: '253',
    label: 'lbl_the_deadline_for_entering_payment_information_has_expired_'
  },
  { value: '', label: 'lbl_an_unknown_error_' }
]

export const FEATURE_SERVICES = {
  reception: 'reception',
  examination: 'examination',
  schedule: 'schedule',
  ecom: 'ecom',
  conference: 'conference',
  badt: 'badt',
  orgmdt: 'orgmdt',
  hssk: 'hssk',
  hsskOverview: 'hsskOverview',
  skb: 'skb',
  bedmgnt: 'bedmgnt',
  ecomMk: 'ecomMk',
  credit: 'credit',
  ecomWarehouse: 'ecomWarehouse',
  mammo: 'mammo',
  anapath: 'anapath'
}

export const ENVELOPE_DOCUMENT_TYPE = {
  EXAMINATION: 1, // PHIẾU KHÁM
  PRESCRIPTION: 2, // ĐƠN THUỐC
  INDICATION_RESULT: 3, // CHỈ ĐỊNH
  SURGERY_FORM: 4, // PHIẾU PHẪU THUẬT THỦ THUẬT
  TREATMENT_FORM: 5, // Phiếu điều trị
  CARE_FORM: 6, // Phiếu chăm sóc
  NUTRITIONAL_FORM: 7, // Phiếu đánh giá dinh dưỡng
  CONFIRM_TREATMENT: 8, // Phiếu xác nhận điều trị
  ADMINISTRATIVE_FORM: 9, // Biểu mẫu hành chính
  SUMMARY_TREATMENT_FORM: 10, // Phiếu sơ kết điều trị
  MDT_FORM: 11, // Biên bản hội chẩn
  PRE_OPERATIVE_ANESTHESIA_FORM: 12, // Phiếu gây mê trước mổ
  OPERATION_FORM: 13, // Tóm tắt thông qua mổ,
  RE_EXAM_FORM: 14, // Giấy hẹn khám lại
  TRANSIT_FORM: 15, // Giấy chuyển tuyến
  HOSPITAL_CHECKOUT_FORM: 16, // Giấy ra viện
  SURGICAL_CERTIFICATE_FORM: 17, // Giấy chứng nhận phẫu thuật
  INSURANCE_CERTIFICATE_FORM: 18, // Giấy chứng nhận nghỉ việc hưởng BHXH
  EMR_FORM: 19, // Bệnh án vào viện,
  MEDICAL_ORDER_FORM: 20, // Phiếu y lệnh truyền
  REIMBURSEMENT_REQUEST_FORM: 21, // Giấy đề nghị thanh toán chi phí hỗ trợ cho bệnh nhân
  REIMBURSEMENT_CONFIRM_FORM: 22, // Giấy biên nhận
  MEDICAL_RECORD_MASTER_PATIENT_INFO: 23, // Bệnh án vào viện Trang 1
  MEDICAL_RECORD_MASTER_A_BENH_AN: 24, // Bệnh án vào viện A - Bệnh án
  MEDICAL_RECORD_MASTER_B_TONG_KET: 25 // Bệnh án vào viện B - Tổng kết
}

export const ENVELOPE_TYPE = {
  SELF_SIGN: 1,
  SEND_SIGN: 2
}

export const SIGNATORY_ROLE = {
  signer: 1,
  follower: 2
}

export const SIGNATORY_STATUS = {
  wait_sign: 1,
  signed: 2
}

export const isDev = process.env.NODE_ENV === 'development'
export const isProd = process.env.NODE_ENV === 'production'

export const AUTO_LOGIN_TYPE = {
  signEnvelop: 1
}

export const PAPER_FORM = {
  PORTRAIT: 1,
  LANDSCAPE: 2
}

export const SIGN_ENVELOPE_STATUS = {
  draft: 1,
  processing: 2,
  completed: 3
}

export const ENVELOPE_NAME_TYPE = {
  patientVisit: 1,
  emr: 2
}
export const BED_STATUS = {
  RECEIVED: 2,
  CANCEL: 3,
  DISCHARGED: 4,
  NOTE: 5,
  SWITCH_BED: 6,
  NEXT_BED: 7
}
