import Vue from 'vue'
const prototype = Vue.prototype
const bucketName = 'hodo-resource'
let aws = window.AWS
const prefix = 'resources/indications/'
const region = 'ap-southeast-1'
const IdentityPoolId = 'ap-southeast-1:ff53cfbf-2595-4ade-83b8-43e3ad25f279'

aws.config.region = region // 1. Enter your region
aws.config.credentials = new aws.CognitoIdentityCredentials({
  IdentityPoolId: IdentityPoolId // 2. Enter your identity pool
})
aws.config.credentials.get(function (err) {
  if (err) alert(err)
  // console.log(AWS.config.credentials);
})

const uploadIndicationFile = {
  async indicationUploadHanlder (
    indication_id,
    files,
    type,
    indication_comment_id = null,
    nameFiles = null,
    examination_result_id = null
  ) {
    if (!files || !files.length) return

    await Promise.all(
      files.map(async (file, i) => {
        if (file) {
          let options = {
            indication_id,
            file,
            type
          }
          if (nameFiles) {
            options.nameFiles = nameFiles[i]
          }
          if (indication_comment_id) {
            options.indication_comment_id = indication_comment_id
          }
          if (examination_result_id) {
            options.examination_result_id = examination_result_id
          }
          let newPrefix =
            prefix +
            indication_id +
            '/' +
            (indication_comment_id
              ? 'comment/' + indication_comment_id + '/'
              : '') +
            (examination_result_id
              ? 'examination-result/' + examination_result_id + '/'
              : '') +
            window.moment().unix() +
            i +
            '_' +
            file.name
          await this.s3ResourcesUploader(newPrefix, options)
        }
      })
    )
  },
  async s3ResourcesUploader (path, options) {
    let self = this
    let bucket = null

    bucket = new aws.S3({
      params: {
        Bucket: bucketName
      }
    })

    if (options.file) {
      const file = options.file
      const objKey = path
      const params = {
        Key: objKey,
        ContentType: file.type,
        Body: file
        // ACL: 'public-read'
      }
      //   this.progress.show = true
      //   this.progress.title = 'Đang tải lên Ảnh DICOM'
      //   this.progress.name = file.name
      //   this.$uploading = true
      let uploadingInfo = {
        id: this.makeid() + window.moment().unix(),
        name: options.nameFiles?.description
          ? options.nameFiles?.description
          : file.name,
        value: 0,
        status: 0
      }
      prototype.$multiUploading.push(uploadingInfo)
      await bucket
        .upload(params)
        .on('httpUploadProgress', function (evt) {
          var uploaded = Math.round((evt.loaded / evt.total) * 100)
          uploadingInfo.value = uploaded
          var foundIndex = prototype.$multiUploading.findIndex(
            (x) => x.id === uploadingInfo.id
          )
          prototype.$multiUploading[foundIndex] = uploadingInfo
        })
        .send(async function (err, data) {
          var foundIndex = prototype.$multiUploading.findIndex(
            (x) => x.id === uploadingInfo.id
          )
          if (err) {
            uploadingInfo.status = 2
            // an error occurred, handle the error
            // console.log('upload failed', err, err.stack)
            prototype.$multiUploading[foundIndex] = uploadingInfo
            setTimeout(() => {
              if (foundIndex > -1) {
                prototype.$multiUploading.splice(foundIndex, 1)
              }
            }, 800)
            return
          }
          // console.log('options', options)
          let params = {
            indication_id: options.indication_id,
            type: options.type,
            name: options.nameFiles?.description
              ? options.nameFiles?.description
              : options.file.name,
            file_name: data.Key
          }
          if (options.indication_comment_id) {
            params.indication_comment_id = options.indication_comment_id
          }
          if (options.examination_result_id) {
            params.examination_result_id = options.examination_result_id
          }
          uploadingInfo.status = 1
          prototype.$multiUploading[foundIndex] = uploadingInfo
          setTimeout(() => {
            if (foundIndex > -1) {
              prototype.$multiUploading.splice(foundIndex, 1)
            }
          }, 800)
          setTimeout(() => {
            if (!prototype.$multiUploading?.length) prototype.$refresh++
          }, 900)
          await self.createIndicationAttach(params.indication_id, params)
        })
    } else {
      return false
    }
  },
  async createIndicationAttach (id, options) {
    let res = null
    await prototype.$rf
      .getRequest('DoctorRequest')
      .createIndicationAttach(id, options)
      .then((r) => {
        // console.log('createCircleAttach', r.data)
        // prototype.$emit('refresh')
        res = r.data
      })
      .catch((error) => {
        console.log('error', error)
      })
    return res
  },
  makeid (length) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
}

export default uploadIndicationFile
