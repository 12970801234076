export const mixinGetPerson = {
  computed: {
  },
  data () {
    return {
      clinic_persons: {},
      search: '',
      doctor_persons: {},
      current_page: 1,
      sort_persons: {
        sort: this.$route.query.sort ? this.$route.query.sort : 'created_at',
        sort_by: this.$route.query.sort_by ? this.$route.query.sort_by : 'desc'
      }
    }
  },
  mounted () {
  },
  methods: {
    searchPerson (search) {
      this.getPersons(10, search)
    },
    async getPersons (limit, search) {
      let self = this
      let params = {
        limit: limit,
        search: search
      }
      if (self.search) {
        params.search = self.search
      }
      if (self.sort_persons) {
        params.sort = self.sort_persons.sort
        params.sort_by = self.sort_persons.sort_by
      }
      if (!self.isClinic) {
        params.clinic_id = this.$clinicId
      }
      if (self.isClinic) {
        let resp = await self.$rf.getRequest('ClinicRequest').getPersons(params)
        self.clinic_persons = resp.data
        self.$router.push({path: '/persons', query: params}).catch(() => {})
      } else {
        let resp = await self.$rf.getRequest('DoctorRequest').getDoctorPersons(params)
        self.doctor_persons = resp.data
        self.doctor_persons.data.forEach(person => {
          Object.assign(person, {name: person.person.name})
        })
        if (self.$router.currentRoute.name !== 'VideoCallManagement') {
          self.$router.push({path: '/doctor/persons', query: params}).catch(() => {})
        }
      }
    },
    async getMorePersons (page) {
      let self = this
      let params = {
        page: page + 1
      }
      if (self.search) {
        params.search = self.search
      }
      if (self.sort_persons) {
        params.sort = self.sort_persons.sort
        params.sort_by = self.sort_persons.sort_by
      }
      if (self.isClinic) {
        self.$router.push({path: '/persons', query: params})
        let resp = await self.$rf.getRequest('ClinicRequest').getPersons(params)
        resp.data.data.forEach(person => {
          self.clinic_persons.data.push(person)
        })
      } else {
        let resp = await self.$rf.getRequest('DoctorRequest').getPersons(params)
        resp.data.data.forEach(person => {
          self.doctor_persons.data.push(person)
        })
        self.doctor_persons.current_page = params.page
      }
    },
    formatDateTime (date_time) {
      return window.moment(date_time).format('HH:mm DD-MM-YYYY')
    },
    sortPersons (params) {
      if (params) {
        this.sort_persons = params
        this.getPersons()
      }
    }
  }
}
