import { ENVELOPE_DOCUMENT_TYPE, ENVELOPE_NAME_TYPE, ENVELOPE_TYPE } from '../utils/constants'
import SignatureRequest from '../api/request/SignatureRequest'
import uploadS3File from './uploadS3File'
import appUtils from './appUtils'

export const mixinSignPdf = {
  methods: {
    getEnvelopeNameByType (type) {
      switch (type) {
        case ENVELOPE_DOCUMENT_TYPE.EXAMINATION:
          return 'Phiếu khám bệnh'

        case ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION:
          return 'Đơn thuốc'

        case ENVELOPE_DOCUMENT_TYPE.INDICATION_RESULT:
          return 'Kết quả chỉ định'

        case ENVELOPE_DOCUMENT_TYPE.SURGERY_FORM:
          return 'Phiếu phẫu thuật/thủ thuật'

        case ENVELOPE_DOCUMENT_TYPE.TREATMENT_FORM:
          return 'Phiếu điều trị'

        case ENVELOPE_DOCUMENT_TYPE.CARE_FORM:
          return 'Phiếu chăm sóc'

        case ENVELOPE_DOCUMENT_TYPE.NUTRITIONAL_FORM:
          return 'Phiếu đánh giá dinh dưỡng'

        case ENVELOPE_DOCUMENT_TYPE.CONFIRM_TREATMENT:
          return 'Phiếu xác nhận điều trị'

        case ENVELOPE_DOCUMENT_TYPE.MDT_FORM:
          return 'Biên bản hội chẩn'

        case ENVELOPE_DOCUMENT_TYPE.PRE_OPERATIVE_ANESTHESIA_FORM:
          return 'Phiếu khám gây mê trước mổ'

        case ENVELOPE_DOCUMENT_TYPE.OPERATION_FORM:
          return 'Tóm tắt thông qua mổ'

        case ENVELOPE_DOCUMENT_TYPE.RE_EXAM_FORM:
          return 'Giấy hẹn khám lại'

        case ENVELOPE_DOCUMENT_TYPE.TRANSIT_FORM:
          return 'Giấy chuyển tuyến'

        case ENVELOPE_DOCUMENT_TYPE.HOSPITAL_CHECKOUT_FORM:
          return 'Giấy ra viện'

        case ENVELOPE_DOCUMENT_TYPE.SURGICAL_CERTIFICATE_FORM:
          return 'Giấy chứng nhận phẫu thuật'

        case ENVELOPE_DOCUMENT_TYPE.INSURANCE_CERTIFICATE_FORM:
          return 'Giấy chứng nhận nghỉ việc hưởng bảo hiểm xã hội'

        case ENVELOPE_DOCUMENT_TYPE.EMR_FORM:
          return 'Bệnh án vào viện'

        case ENVELOPE_DOCUMENT_TYPE.MEDICAL_ORDER_FORM:
          return 'Phiếu y lệnh truyền'

        case ENVELOPE_DOCUMENT_TYPE.REIMBURSEMENT_REQUEST_FORM:
          return 'Giấy đề nghị thanh toán chi phí hỗ trợ cho bệnh nhân'

        case ENVELOPE_DOCUMENT_TYPE.REIMBURSEMENT_CONFIRM_FORM:
          return 'Giấy biên nhận'

        case ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_PATIENT_INFO:
          return 'Bệnh án vào viện - Thông tin hành chính'

        case ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_A_BENH_AN:
          return 'Bệnh án vào viện - A - Bệnh án'
        case ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_B_TONG_KET:
          return 'Bệnh án vào viện - B - Tổng kết'

        default:
          return ''
      }
    },
    async getSignProcedures (docType) {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: docType,
          ws_id: this.$globalClinic?.id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data?.data?.[0]
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetEnvelopeByDocumentID (docID, docType) {
      try {
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByDocumentID({
          doc_id: docID,
          doc_type: docType
        })

        return response.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.isLoading = false
      }
    },
    async handleCheckSignEnvelope (
      docType,
      docID,
      PDFViewerRef,
      ModalSignPDFRef,
      isPatientSign = false
    ) {
      try {
        const signEnvelope = await this.handleGetEnvelopeByDocumentID(
          docID,
          docType
        )

        if (!signEnvelope?.id) return

        const signatories = signEnvelope?.envelope_signatories?.map((item) => ({
          ...item,
          user: item?.user_info,
          user_id: item?.user_id,
          sign_node_id: item?.node_signatory_info?.sign_node_id
        }))

        const signerInfo = signEnvelope?.envelope_signatories?.find(
          (item) => (item?.user_id === this.$user?.id && !isPatientSign) || (item?.person_id && isPatientSign)
        )

        const s3Url = await uploadS3File.getLinkVideoAWS(
          signEnvelope?.document_url
        )

        if (signerInfo?.status === 2) {
          if (PDFViewerRef) {
            this.$nextTick(() => {
              this.$refs[PDFViewerRef].handleOpen(
                s3Url,
                docType,
                Number(docID),
                false,
                false
              )
            })
          }

          return signEnvelope
        } else {
          const hasPermissionSignatory = signatories?.some(
            (item) => item?.user_id === this.$user?.id
          )

          if (!hasPermissionSignatory) {
            this.$toast.open({
              message: 'Bạn không có quyền ký tài liệu này',
              type: 'error'
            })

            if (PDFViewerRef) {
              this.$nextTick(() => {
                this.$refs[PDFViewerRef].handleOpen(
                  s3Url,
                  docType,
                  Number(docID),
                  false,
                  false
                )
              })
            }

            return signEnvelope
          }

          this.$nextTick(() => {
            this.$refs[ModalSignPDFRef].handleOpen(
              s3Url,
              docType,
              Number(docID),
              signerInfo?.user_id || undefined,
              signatories || [],
              signEnvelope,
              signEnvelope.sign_procedure_id,
              isPatientSign
            )
          })
        }

        return signEnvelope
      } catch (error) {
        console.log(error)
      }
    },
    async handleProcessProcedureData (callback, docType, isPatientSign) {
      const procedure = await this.getSignProcedures(docType)

      const signatories = procedure?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          const user = this.getUserByRole(item?.user_role, callback, isPatientSign)

          return {
            ...item,
            node_signatory_id: item?.id,
            user,
            user_id: user?.id,
            person_id: user?.person_id,
            sign_node_id: node?.id
          }
        })

        return arr.concat(signs)
      }, [])

      return { ...procedure, signatories }
    },
    handleGetEnvelopeName (type, data) {
      switch (type) {
        case ENVELOPE_NAME_TYPE.patientVisit: {
          const patientName = data?.person?.name || ''
          const startTime = data?.start_time ? window.moment(data?.start_time).utc().format('HH:mm') : ''
          const endTime = data?.end_time ? window.moment(data?.end_time).subtract(7, 'hours').utc().format('HH:mm') : ''
          const date = data?.start_time ? window.moment(data?.start_time).utc().format('DD/MM/YYYY') : ''
          const formatData = startTime ? `${startTime} - ${endTime} ${date}` : ''

          return `${patientName} - ${formatData}`
        }

        case ENVELOPE_NAME_TYPE.emr:
          const patientName = data?.person?.name || ''
          const refID = data?.ref_id ? `- ${data?.ref_id}` : ''
          const emrName = data?.medical_record_name ? `- ${data?.medical_record_name}` : ''

          return `${patientName} ${refID} ${emrName}`

        default:
          return ''
      }
    },
    async handleCreateEnvelope (signatories, documentUrl, procedureID, documentType, documentID, envelopeName) {
      try {
        const { envelopeUrl } = await this.handleProcessDocument(documentUrl, documentID, documentType)
        // const signatories = this.handleProcessEnvelopSignatories()
        const params = {
          sign_procedure_id: procedureID,
          document_id: documentID,
          document_type: documentType,
          // document_url: this.do,
          envelope_name:
            envelopeName || this.getEnvelopeNameByType(documentType),
          document_url: envelopeUrl,
          envelope_description: '',
          envelope_type: ENVELOPE_TYPE.SELF_SIGN,
          issuer: this.$user?.id,
          issuer_date: window.moment().valueOf(),
          envelope_signatories: signatories
          // expired_date: '',
        }
        const request = new SignatureRequest()

        const response = await request.createEnvelope(params)

        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleProcessDocument (documentUrl, documentID, documentType) {
      try {
        const newImageSrc = new URLSearchParams(documentUrl).toString()
        const formatImageSrc = newImageSrc.substring(0, newImageSrc.length - 1)
        const responseImage = await this.$rf
          .getRequest('DoctorRequest')
          .postUrlImage(formatImageSrc)
        const base64File = responseImage.data?.data || ''

        const [envelopeUrl] = await this.handleUploadFilesAWS(base64File, documentID, documentType)

        return { envelopeUrl, documentBase64Data: base64File }
      } catch (error) {
        console.log(error)
      }
    },
    async handleUploadFilesAWS (base64, documentID, documentType) {
      if (!documentID) return

      const formatBase64 = 'data:application/pdf;base64,' + base64

      const file = appUtils.convertBase64tToFile(
        formatBase64,
        this.getEnvelopeNameByType(documentType)
      )

      const prefixPath = `resources/sign_envelopes/${documentType}/`

      const resultUrl = await uploadS3File.uploadHandler(
        documentID,
        window.moment().valueOf(),
        [file],
        1,
        prefixPath
      )

      return resultUrl?.map((item) => item?.url)
    },
    async handleUpdateSignEnvelope (envelopeID, signatories, documentUrl, documentType, documentID, envelopeName) {
      try {
        const { envelopeUrl } = await this.handleProcessDocument(documentUrl, documentID, documentType)
        const params = {
          document_id: documentID,
          document_type: documentType,
          envelope_name:
            envelopeName || this.getEnvelopeNameByType(documentType),
          document_url: envelopeUrl,
          envelope_description: '',
          envelope_type: ENVELOPE_TYPE.SELF_SIGN,
          issuer: this.$user?.id,
          issuer_date: window.moment().valueOf(),
          envelope_signatories: signatories
        }
        const request = new SignatureRequest()

        const response = await request.updateEnvelope(envelopeID, params)

        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
