import Vue from 'vue'

const responseHanlder = {
  _errorHandler (err) {
    // Hide loading.
    if (err.response) {
      if (err.response.data.status === 414) {
        let firstKey = Object.keys(err.response.data.message)[0]
        if (firstKey) {
          alert(err.response.data.message[firstKey][0])
        }
      }
      if (err.response.status === 422) {
        let messages = Object.values(err.response.data.errors).map(errs => '<span> - ' + errs[0] + '</span>')
        Vue.prototype.$toast.open({
          message: messages.join('<br />'),
          type: 'warning'
        })
      } else {
        Vue.prototype.$toast.open({
          message: err.response?.data?.message || 'Thất bại, vui lòng thử lại',
          type: 'warning'
        })
      }
      if ((err.response.status === 401 || err.response.status === 406)) {
      }
    }
  }
}
export default responseHanlder
