import Vue from 'vue'
const prototype = Vue.prototype
const bucketName = 'hodo-resource'
let aws = window.AWS
const prefix = 'resources/indications/'
const region = 'ap-southeast-1'
const IdentityPoolId = 'ap-southeast-1:ff53cfbf-2595-4ade-83b8-43e3ad25f279'

aws.config.region = region // 1. Enter your region
aws.config.credentials = new aws.CognitoIdentityCredentials({
  IdentityPoolId: IdentityPoolId // 2. Enter your identity pool
})
aws.config.credentials.get(function (err) {
  if (err) alert(err)
  // console.log(AWS.config.credentials);
})
let total = 0
let done = 0
let s3Files = []
const uploadEmrIndicationFile = {
  async indicationUploadHanlder (
    emr_indication_result_id,
    files,
    type,
    nameFiles = null
  ) {
    if (!files || !files.length) return
    total = files.length
    done = 0
    s3Files = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        let options = {
          file,
          type,
          emr_indication_result_id: emr_indication_result_id
        }
        if (nameFiles) {
          options.nameFiles = nameFiles[i]
        }
        const newPrefix =
          prefix +
          emr_indication_result_id +
          '/' +
          window.moment().unix() +
          i +
          '_' +
          file.name
        this.s3ResourcesUploader(newPrefix, options)
      }
    }
  },
  async s3ResourcesUploader (path, options) {
    let self = this
    let bucket = null
    bucket = new aws.S3({
      params: {
        Bucket: bucketName
      }
    })

    if (options.file) {
      const file = options.file
      const objKey = path
      const params = {
        Key: objKey,
        ContentType: file.type,
        Body: file
        // ACL: 'public-read'
      }
      //   this.progress.show = true
      //   this.progress.title = 'Đang tải lên Ảnh DICOM'
      //   this.progress.name = file.name
      //   this.$uploading = true
      let uploadingInfo = {
        id: this.makeid() + window.moment().unix(),
        name: options.nameFiles?.description
          ? options.nameFiles?.description
          : file.name,
        value: 0,
        status: 0
      }
      prototype.$multiUploading.push(uploadingInfo)
      await bucket
        .upload(params)
        .on('httpUploadProgress', function (evt) {
          var uploaded = Math.round((evt.loaded / evt.total) * 100)
          uploadingInfo.value = uploaded
          var foundIndex = prototype.$multiUploading.findIndex(
            (x) => x.id === uploadingInfo.id
          )
          prototype.$multiUploading[foundIndex] = uploadingInfo
        })
        .send(function (err, data) {
          done++
          var foundIndex = prototype.$multiUploading.findIndex(
            (x) => x.id === uploadingInfo.id
          )
          if (err) {
            uploadingInfo.status = 2
            // an error occurred, handle the error
            console.log('upload failed', err, err.stack)
            prototype.$multiUploading[foundIndex] = uploadingInfo
            setTimeout(() => {
              if (foundIndex > -1) { prototype.$multiUploading.splice(foundIndex, 1) }
            }, 800)
            return
          }
          uploadingInfo.status = 1
          prototype.$multiUploading[foundIndex] = uploadingInfo
          self.postEmrIndicationResultFile({
            emr_indication_result_id: options.emr_indication_result_id,
            type: options.type,
            name: options.nameFiles?.description
              ? options.nameFiles?.description
              : options.file.name,
            file_name: data.Key
          })
          setTimeout(() => {
            if (foundIndex > -1) { prototype.$multiUploading.splice(foundIndex, 1) }
          }, 800)
        })
      return true
    } else {
      return false
    }
  },
  async postEmrIndicationResultFile (options) {
    if (done < total && options) {
      s3Files.push({
        type: options.type,
        name: options.name,
        file_name: options.file_name
      })
    } else {
      s3Files.push({
        type: options.type,
        name: options.name,
        file_name: options.file_name
      })
      let r = await prototype.$rf
        .getRequest('DoctorRequest')
        .postEmrIndicationResultFile(options.emr_indication_result_id, {
          files: s3Files
        })
        .then((r) => {
          // prototype.$emit('refresh')
          return r.data
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          setTimeout(() => {
            if (!prototype.$multiUploading?.length) prototype.$refresh++
          }, 900)
        })
      return r
    }
  },
  makeid (length) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
}

export default uploadEmrIndicationFile
