export const mixinQueryParams = {
  methods: {
    cleanObjectValues (obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (!value) {
          delete obj[key]
        }
      }
      return obj
    },

    handleSetFilter (data) {
      const currentQuery = this.$route.query
      const queryData = {
        ...currentQuery,
        ...data
      }
      const cleanQuery = this.cleanObjectValues(queryData)

      this.$router.replace({
        query: cleanQuery
      }).catch(() => {})
    },
    handleClearQuery () {
      this.$router.replace({
        query: null
      }).catch(() => {})
    }
  }
}
