import appUtils from './appUtils'
import firebase from 'firebase/app'
import rolesPermissions from './rolesPermissions'
export const mixinUser = {
  created () {
    let self = this

    if (self.$route.query.token) {
      self.loginWithToken(self.$route.query.token)
      setTimeout(() => {
        let query = Object.assign({}, self.$route.query)
        delete query.token
        self.$router.replace({ query })
      }, 100)
    }
  },
  methods: {
    async loginWithToken (token) {
      if (token) {
        appUtils.setLocalToken(token)
        await this.setLocalUserInfo().then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 300)
        })
      }
    },
    async setLocalUserInfo () {
      let resp = await this.$rf.getRequest('AuthRequest').me()
      if (resp) {
        appUtils.setLocalUser(resp.data)
      }
      return resp
    },
    async getCurrentUser () {
      let self = this

      let user = appUtils.getLocalUser()
      if (!user) {
        // Redirect to login page.
        return self.$router.push({ path: '/login' }).catch((_) => {})
      } else {
        if (appUtils.isClinic() || appUtils.isDoctor() || appUtils.isAdmin()) {
          self.user = user
        } else {
          // self.$swal.fire({
          //   html: '<p>Tài khoản này chỉ được đăng nhập trên ứng dụng Hodo điện thoại!</p>',
          //   icon: 'error'
          // })
          // self.onLogout()
        }
      }
    },
    onLogout () {
      var r = confirm('Bạn có muốn thoát tài khoản này không?')
      if (!r) {
        return false
      }

      this.user = null
      this.onClearUser()
      // Redirect to login page.
      setTimeout(() => {
        return this.$router.push({ path: '/login' }).catch(() => {})
      }, 300)
    },
    onClearUser () {
      let self = this

      self.user = null
      appUtils.removeLocalToken()
      appUtils.removeFeatureList()
      appUtils.removeFeatureWsList()
      appUtils.removeFeatureOrgList()
      appUtils.removeLocalUser()
      appUtils.removeClinicId()
      appUtils.removeDoctorClinic()
      appUtils.removeGlobalOrg()
      appUtils.removeFirebaseAccessToken()
      rolesPermissions.removePermissionsList()
      rolesPermissions.rmRole()
      window.localStorage.removeItem('isAdmin')
      window.localStorage.removeItem('app_mode')

      try {
        firebase.messaging().deleteToken()
      } catch (e) {
        // statements
        console.log(e)
      }
      if (window.client) {
        window.client.disconnect()
        setTimeout(() => {
          window.client = null
        }, 300)
      }
    }
  }
}
