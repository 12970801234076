import Vue from 'vue'
const prototype = Vue.prototype
const bucketName = 'hodo-resource'
let aws = window.AWS
const prefix = 'resources/patientVisits/'
const region = 'ap-southeast-1'
const IdentityPoolId = 'ap-southeast-1:ff53cfbf-2595-4ade-83b8-43e3ad25f279'

aws.config.region = region // 1. Enter your region
aws.config.credentials = new aws.CognitoIdentityCredentials({
  IdentityPoolId: IdentityPoolId // 2. Enter your identity pool
})
aws.config.credentials.get(function (err) {
  if (err) alert(err)
  // console.log(AWS.config.credentials);
})

const uploadS3File = {
  async uploadHandler (folderId, nameId, files, type, prefixProp) {
    // folderId: HSSK/Circle/Indication (params)
    // nameId: ID nhận diện file
    // type: 3: pacdicom
    if (!files || !files.length) return

    const resultURL = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (file) {
        let options = {
          file: file,
          type
        }
        if (folderId) {
          options.folder_id = folderId
        }
        const _prefix = type === 3 ? 'pacdicom/' : prefix

        let newPrefix = `${prefixProp || _prefix}${folderId}/${nameId ? `${nameId}_${file.name}` : file.name}`

        const fileURL = await this.s3ResourcesUploader(newPrefix, options)
        resultURL.push(fileURL)
      } else {
        resultURL.push(null)
      }
    }

    return resultURL
  },
  async s3ResourcesUploader (path, options) {
    let self = this
    let bucket = null
    const _bucketName = options.type === 3 ? 'hodo-pacdicom' : bucketName

    bucket = new aws.S3({
      params: {
        Bucket: _bucketName
      }
    })

    if (options.file) {
      const file = options.file
      const objKey = path
      const params = {
        Key: objKey,
        ContentType: file.type,
        Body: file
        // ACL: 'public-read'
      }
      //   this.progress.show = true
      //   this.progress.title = 'Đang tải lên Ảnh DICOM'
      //   this.progress.name = file.name
      //   this.$uploading = true
      let uploadingInfo = {
        id: this.makeid() + window.moment().unix(),
        name: file.name,
        value: 0,
        status: 0
      }
      prototype.$multiUploading.push(uploadingInfo)

      const UploadBucket = await bucket
        .upload(params)

      await bucket
        .upload(params).on('httpUploadProgress', function (evt) {
          var uploaded = Math.round((evt.loaded / evt.total) * 100)
          uploadingInfo.value = uploaded
          var foundIndex = prototype.$multiUploading.findIndex(
            (x) => x.id === uploadingInfo.id
          )
          prototype.$multiUploading[foundIndex] = uploadingInfo
        }).send(async function (err, data) {
          var foundIndex = prototype.$multiUploading.findIndex(
            (x) => x.id === uploadingInfo.id
          )
          if (err) {
            uploadingInfo.status = 2
            // an error occurred, handle the error
            console.log('upload failed', err, err.stack)
            prototype.$multiUploading[foundIndex] = uploadingInfo
            setTimeout(() => {
              if (foundIndex > -1) {
                prototype.$multiUploading.splice(foundIndex, 1)
              }
            }, 800)
            return
          }

          // PACS=3

          uploadingInfo.status = 1
          prototype.$multiUploading[foundIndex] = uploadingInfo
          setTimeout(() => {
            if (foundIndex > -1) {
              prototype.$multiUploading.splice(foundIndex, 1)
            }
          }, 800)
          setTimeout(() => {
            if (!prototype.$multiUploading?.length) prototype.$refresh++
          }, 900)
        })

      const result = UploadBucket.promise().then(async data => {
        let pac
        if (options.type === 3) {
          pac = await self.onUploadPac(
            options.file.name,
            options.type,
            data.Key
          )
        }
        return { url: data.Key, pac_id: pac?.id }
      })
      return result
    } else {
      return false
    }
  },
  async onUploadPac (name, type, key) {
    const params = {
      name: name,
      file_name: key
    }
    let r = await prototype.$rf.getRequest('DoctorRequest').createPacs(params)

    if (type === 3 && r?.data) {
      await this.pacUpdate(key, r?.data)
    }
    return r?.data
  },
  async pacUpdate (url, access) {
    const params = {
      files: url
    }
    let r = await prototype.$rf
      .getRequest('DoctorRequest')
      .updatePacs(access.id, params)

    return r
  },

  makeid (length) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },
  async getLinkVideoAWS (file_name) {
    try {
      const params = {
        fileName: file_name
      }
      const header = {
        'x-api-key': process.env.VUE_APP_AWS_API_KEY
      }

      const response = await prototype.$rf
        .getRequest('AuthRequest').getLinkHodoResourceAws(params, header, false)

      return response.data.data
    } catch (error) {
      console.log(error)
    }
  }
}

export default uploadS3File
